import React, { useEffect, useState } from "react";
import useIconService from "../hooks/iconService/useIconService";

const Loading = ({ onLoadingComplete }) => {
  const [opacity, setOpacity] = useState(false);
  const [translate, setTranslate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { iconVersion } = useIconService();

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (!opacity) {
      setTimeout(() => {
        setOpacity(true);
        setTimeout(() => {
          if (onLoadingComplete) {
            onLoadingComplete(true);
            setTranslate(true);
          }
        }, 300);
      }, 2900);
    }
  }, [onLoadingComplete, opacity]);

  return (
    <div
      className={`${
        translate ? "-translate-y-full" : "translate-y-0"
      } absolute transition-all ease-out duration-700 flex items-center justify-center top-0 w-full h-full dark:bg-black bg-white z-50 font-inter shadow dark:text-white text-primaryBlack`}
    >
      <div
        className={`flex items-center transition-opacity transition-transform ease-out duration-700 ${
          loaded ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
        }`}
      >
        <h1 className="text-xl lg:text-2xl font-medium mr-2">Mage Icons</h1>
        <div className="flex items-center font-[500] border border-white3 dark:border-black2 rounded-[10px] py-1.5 px-2 text-[15px]">
          v{iconVersion}
        </div>
      </div>
      <div
        className={`fixed bottom-32 transition-all ease-out duration-300 ${
          opacity ? "opacity-0" : "opacity-100"
        } `}
      >
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default Loading;
