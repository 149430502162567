import { useCallback, useEffect, useState } from "react";
import { firestore } from "../../config/app.config";
import { addDoc, collection } from "@firebase/firestore";
import { DateTime } from "luxon";

const useRequestIcons = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmailAddress] = useState("");
  const [notes, setNotes] = useState("");

  const [nameValid, setNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [notesValid, setNotesValid] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [formProcessing, setFormProcesssing] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const resetForm = () => {
    setFullName("");
    setEmailAddress("");
    setNotes("");
    setFormProcesssing(false);
    setFormSubmitted(false);
    setFormError(false);
  };

  const submitForm = useCallback(async () => {
    if (formValid) {
      try {
        setFormProcesssing(true);
        await addDoc(
          collection(firestore, process.env.REACT_APP_REQUEST_FORM),
          {
            name: fullName,
            email: email,
            note: notes,
            requestedAt: DateTime.now().setZone("Asia/Kathmandu").toISO(),
          }
        );
        setFormSubmitted(true);
      } catch (e) {
        console.error(e);
        setFormError(true);
      } finally {
        setFormProcesssing(false);
      }
    }
  }, [fullName, email, notes, formValid]);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (fullName?.trim()?.length > 2) {
      setNameValid(true);
    } else {
      setNameValid(false);
    }

    if (emailRegex.test(email ?? "")) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }

    if (notes?.trim()?.length > 4) {
      setNotesValid(true);
    } else {
      setNotesValid(false);
    }
  }, [fullName, email, notes]);

  useEffect(() => {
    setFormValid(nameValid && emailValid && notesValid);
  }, [nameValid, emailValid, notesValid]);

  return {
    setFullName,
    setEmailAddress,
    setNotes,
    nameValid,
    emailValid,
    notesValid,
    formValid,
    resetForm,
    fullName,
    email,
    notes,
    submitForm,
    formProcessing,
    formSubmitted,
    formError,
  };
};

export default useRequestIcons;
