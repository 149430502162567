import React, { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import ShyIcon from "../icons/ShyIcon";
import TextField from "./TextField";
import TextArea from "./TextArea";
import ReCAPTCHA from "react-google-recaptcha";
import useCaptchaService from "../hooks/captchaService/useCaptchaService";
import useRequestIcons from "../hooks/requestIcons/useRequestIcons";

const RequestIconsPopup = ({ closeModal, modalOpen }) => {
  const captchaRef = useRef();
  const { token, setToken, resetToken } = useCaptchaService();
  const {
    setFullName,
    setEmailAddress,
    setNotes,
    formValid,
    resetForm,
    fullName,
    email,
    notes,
    submitForm,
    formSubmitted,
    formError,
    formProcessing,
  } = useRequestIcons();

  const [disableButton, setDisableButton] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (formSubmitted || formError) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setTimeout(() => {
          captchaRef.current?.reset();
          resetToken();
          resetForm();
        }, 500);
      }, 2000);
    }
  }, [formSubmitted, formError, resetToken, resetForm]);

  useEffect(() => {
    if (token && formValid) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [token, formValid]);

  useEffect(() => {
    if (!modalOpen) {
      captchaRef.current?.reset();
      resetToken();
      resetForm();
    }
  }, [modalOpen, resetToken, resetForm]);

  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[359px] w-full p-5"
    >
      <h1 className="flex text-xl leading-8 font-[550] mb-5">
        <span className="mr-3">
          <ShyIcon />
        </span>
        Request Icons
      </h1>
      <h2 className="text-sm font-[450] mb-1.5">
        Full Name <span className="text-error">*</span>
      </h2>
      <div className="mb-4">
        <TextField
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
      </div>
      <h2 className="text-sm font-[450] mb-1.5">
        Email Address <span className="text-error">*</span>
      </h2>
      <div className="mb-4">
        <TextField
          type="email"
          value={email}
          onChange={(e) => setEmailAddress(e.target.value)}
        />
      </div>
      <h2 className="text-sm font-[450] mb-1.5">
        Icons Request & Additional Notes <span className="text-error">*</span>
      </h2>
      <div className="mb-4">
        <TextArea value={notes} onChange={(e) => setNotes(e.target.value)} />
      </div>
      <div className="flex items-center justify-center mb-4 w-full">
        <ReCAPTCHA
          ref={captchaRef}
          onChange={(token) => {
            setToken(token);
          }}
          onExpired={() => {
            resetToken();
          }}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
        />
      </div>
      <button
        onClick={() => {
          if (!disableButton) {
            submitForm();
          }
        }}
        disabled={disableButton || formProcessing}
        className={`${
          disableButton || formProcessing
            ? "bg-disabled"
            : "bg-gradient-to-b from-[#1484EF] to-[#0072DE] cursor-pointer"
        } flex items-center justify-center w-full h-11 rounded-xl py-2.5 px-3.5`}
      >
        <div className="text-white font-medium text-[17px] leading-6">
          Request Icons
        </div>
      </button>
      <div
        className={`${
          showToast ? "opacity-100" : "opacity-0"
        } fixed duration-500 z-10 ease-in-out bottom-5 right-5 h-6 bg-primaryBlack min-w-28 text-white py-1.5 px-2 flex justify-center items-center rounded-lg pointer-events-none`}
      >
        {formSubmitted && "Icon request submitted"}
        {formError && "Something went wrong, please try again later"}
      </div>
    </Modal>
  );
};

export default RequestIconsPopup;
