import React, { useEffect, useRef, useState } from "react";
import CopyIcon from "../icons/CopyIcon";

const IconCard = ({ Icon, iconName, onClick, svg }) => {
  const [onHover, setHover] = useState(false);
  const [animateClass, setAnimateClass] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnHover = () => {
    setHover(true);
  };

  const handleOnExit = () => {
    setHover(false);
  };

  const handleCopy = () => {
    setShowTooltip(true);
    if (onClick) {
      onClick(svg);
    }
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const textRef = useRef();

  useEffect(() => {
    if (
      textRef.current &&
      textRef.current.scrollWidth > textRef.current.clientWidth
    ) {
      setAnimateClass("animate-marquee");
    }
  }, [onHover]);

  return (
    <div
      onMouseLeave={handleOnExit}
      onMouseOver={handleOnHover}
      className="relative bg-white dark:bg-primaryBlack w-20 h-20 md:w-20 md:h-20 rounded-2xl border dark:border-primaryBlack flex justify-center items-center"
    >
      {Icon && Icon}
      {onHover && (
        <div
          onClick={handleCopy}
          className="absolute flex justify-center items-center top-2.5 right-2.5 w-6 h-6 bg-black2 rounded-lg cursor-pointer"
        >
          <CopyIcon />
        </div>
      )}
      {onHover && (
        <div
          ref={textRef}
          className="absolute overflow-hidden text-gray1 dark:text-white text-center bottom-0 left-0 rounded-bl-2xl rounded-br-2xl w-full h-6 bg-gray4 dark:bg-black2"
        >
          <span className={`block px-19 whitespace-nowrap ${animateClass}`}>
            {iconName && iconName}
          </span>
        </div>
      )}

      <div
        className={`absolute ${
          showTooltip ? "opacity-100" : "opacity-0"
        } duration-500 z-10 ease-in-out -top-8 h-6 bg-primaryBlack w-28 text-white py-1.5 px-2 flex justify-center items-center rounded-lg pointer-events-none`}
      >
        SVG Copied
      </div>
    </div>
  );
};

export default IconCard;
