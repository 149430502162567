import { useContext } from "react";
import { PopupServiceContext } from "./popupServiceProvider";

const usePopupService = () => {
  const {
    showLicense,
    setShowLicense,
    showSupportUs,
    setShowSupportUs,
    showRequestIcons,
    setShowRequestIcons,
    showPrivacy,
    setShowPrivacy,
    showAboutUs,
    setShowAboutUs,
    showChangeLog,
    setShowChangeLog,
  } = useContext(PopupServiceContext);

  return {
    showLicense,
    setShowLicense,
    showSupportUs,
    setShowSupportUs,
    showRequestIcons,
    setShowRequestIcons,
    showPrivacy,
    setShowPrivacy,
    showAboutUs,
    setShowAboutUs,
    showChangeLog,
    setShowChangeLog,
  };
};

export default usePopupService;
