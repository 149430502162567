import React from "react";
import { MultiplySquareIcon } from "mage-icons-react/stroke";
import Modal from "./Modal";

const LicensePopup = ({ closeModal, modalOpen }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[579px] w-full p-5 "
    >
      <h1 className="text-xl leading-8 font-[550] mb-3">Licensing</h1>
      <p className="text-base font-normal mb-5">
        Mage icons is an open source icon system licensed under the{" "}
        <a
          href="https://www.apache.org/licenses/LICENSE-2.0"
          target="_blank"
          rel="noreferrer"
          className="underline text-base font-normal cursor-pointer text-accentBlue"
        >
          Apache License 2.0.
        </a>{" "}
        These icons can be used for personal and commercial use for free, but
        they cannot be sold and distributed under another name.
      </p>
      <h1 className="text-xl leading-8 font-[550] mb-3">Prohibitions</h1>
      <div className="flex justify-start mb-4">
        <MultiplySquareIcon className="w-6 h-6 mr-2" />
        <p className="text-base font-normal">
          Reselling and redistribution of the icons as a sole entity
        </p>
      </div>
      <div className="flex justify-start">
        <MultiplySquareIcon className="w-6 h-6 mr-2" />
        <p className="text-base font-normal ">
          Redistribution of the icon library under a different alias
        </p>
      </div>
    </Modal>
  );
};

export default LicensePopup;
