import React, { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "mage-icons-react/stroke";

const Accordion = (props) => {
  const { label, children, subLabel, open, onClick } = props;
  const [accordionOpen, setAccordionOpen] = useState(open ?? false);

  useEffect(() => {
    setAccordionOpen(open);
  }, [open]);

  return (
    <div class="border-t border-white3 dark:border-black2">
      <h2 class="mb-0">
        <button
          class="group relative flex w-full items-center px-5 py-4 text-left text-base transition"
          type="button"
          data-te-collapse-init
          data-te-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
          onClick={() => {
            if (onClick) {
              onClick(!accordionOpen);
            }
          }}
        >
          <span className="text-base font-medium">{label}</span>
          {subLabel && accordionOpen && (
            <span class="ml-auto h-[28px] rounded-[10px] p-2 flex text-[13px] font-[550] mr-2 items-center bg-accentBlue text-white">
              {subLabel}
            </span>
          )}
          {subLabel && !accordionOpen && (
            <span class="ml-auto h-[28px] border border-white3 dark:border-black2 rounded-[10px] p-2 flex text-[13px] font-[550] mr-2 items-center">
              {subLabel}
            </span>
          )}
          {!accordionOpen && <ChevronDownIcon className="w-4 h-4" />}
          {accordionOpen && <ChevronUpIcon className="w-4 h-4" />}
        </button>
      </h2>
      <div
        class={accordionOpen ? "!visible" : "!hidden"}
        data-te-collapse-item
        data-te-collapse-show
        aria-labelledby="headingOne"
        data-te-parent="#accordionExample"
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
