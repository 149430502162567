import React from "react";

const DownloadIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 14.1029V3.5229"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.93079 10.3694L11.4201 13.8587C11.5744 14.0115 11.7828 14.0973 12 14.0973C12.2172 14.0973 12.4256 14.0115 12.5799 13.8587L16.0692 10.3694"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.86157 12.8821V16.9513C3.86157 17.4909 4.07593 18.0084 4.45749 18.39C4.83906 18.7716 5.35657 18.9859 5.89618 18.9859H18.1038C18.6434 18.9859 19.1609 18.7716 19.5425 18.39C19.9241 18.0084 20.1384 17.4909 20.1384 16.9513V12.8821"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
