import React, { useEffect, useState } from "react";
import { mageIconList } from "mage-icons-react";

export const IconServiceContext = React.createContext();
export const IconSizes = {
  "24px:": "24px",
  "20px:": "20px",
};

export const IconServiceProvider = ({ children }) => {
  const [iconType, setIconType] = useState("stroke");
  const [iconSearch, setIconSearch] = useState();
  const [iconVersion] = useState(mageIconList?.version);
  const [iconSize, setIconSize] = useState(
    IconSizes["24px:"]
    // window.localStorage.getItem("iconSize") ?? IconSizes["24px:"]
  );
  const [subIconType, setSubIconType] = useState("bw");

  useEffect(() => {
    window.localStorage.setItem("iconSize", iconSize);
  }, [iconSize]);

  return (
    <IconServiceContext.Provider
      value={{
        iconType,
        setIconType,
        iconSearch,
        setIconSearch,
        iconVersion,
        iconSize,
        setIconSize,
        subIconType,
        setSubIconType,
      }}
    >
      {children}
    </IconServiceContext.Provider>
  );
};
