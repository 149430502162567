import React from "react";
import LicensePopup from "./LicensePopup";
import usePopupService from "../hooks/popupService/usePopupService";
import SupportUsPopup from "./SupportUsPopup";
import RequestIconsPopup from "./RequestIconsPopup";
import PrivacyPopup from "./PrivacyPopup";
import AboutUsPopup from "./AboutUsPopup";
import ChangeLogPopup from "./ChangelogPopup";

const Popups = () => {
  const {
    showLicense,
    setShowLicense,
    showSupportUs,
    setShowSupportUs,
    showRequestIcons,
    setShowRequestIcons,
    showPrivacy,
    setShowPrivacy,
    showAboutUs,
    setShowAboutUs,
    showChangeLog,
    setShowChangeLog,
  } = usePopupService();

  return (
    <div>
      <LicensePopup
        modalOpen={showLicense}
        closeModal={() => {
          setShowLicense(false);
        }}
      />
      <SupportUsPopup
        modalOpen={showSupportUs}
        closeModal={() => {
          setShowSupportUs(false);
        }}
      />
      <RequestIconsPopup
        modalOpen={showRequestIcons}
        closeModal={() => {
          setShowRequestIcons(false);
        }}
      />
      <PrivacyPopup
        modalOpen={showPrivacy}
        closeModal={() => {
          setShowPrivacy(false);
        }}
      />
      <AboutUsPopup
        modalOpen={showAboutUs}
        closeModal={() => {
          setShowAboutUs(false);
        }}
      />
      <ChangeLogPopup
        modalOpen={showChangeLog}
        closeModal={() => {
          setShowChangeLog(false);
        }}
      />
    </div>
  );
};

export default Popups;
