import React, { useContext, useState } from "react";
import SupportIcon from "../assets/icons/support.png";
import GithubIcon from "../icons/GithubIcon";
import ElevatedButton from "./ElevatedButton";
import DownloadIcon from "../icons/DownloadIcon";
import { DashMenuIcon } from "mage-icons-react/stroke";
import useIconService from "../hooks/iconService/useIconService";
import usePopupService from "../hooks/popupService/usePopupService";
import useDownloadService from "../hooks/downloadService/useDownloadService";
import BrightnessSelector from "./BrightnessSelector";
import SunIcon from "../icons/SunIcon";
import MoonIcon from "../icons/MoonIcon";
import HomeContext from "../pages/HomeContext";

const Header = () => {
  const [nav, setNav] = useState(false);
  const { iconVersion } = useIconService();
  const {
    setShowLicense,
    setShowSupportUs,
    setShowChangeLog,
    setShowRequestIcons,
  } = usePopupService();
  const { downloadURL } = useDownloadService();
  const context = useContext(HomeContext);
  const { brightness, setBrightness } = context;
  const [hidden, setHidden] = useState(false);

  const handleNav = () => {
    if (!nav) {
      setHidden(!nav);
      setTimeout(() => {
        setNav(!nav);
      }, 50);
    } else {
      setNav(!nav);
      setTimeout(() => {
        setHidden(!nav);
      }, 500);
    }
  };

  return (
    <div className="flex items-center justify-center h-20 w-full mx-auto pt-3 px-5 bg-white md:bg-transparent dark:bg-primaryBlack dark:bg-none sticky top-0 z-20 md:relative md:z-0">
      <div className="flex items-center justify-between w-full max-w-[1120px]">
        <div className="flex items-center">
          <h1 className="text-xl lg:text-2xl font-medium mr-2">Mage Icons</h1>
          <div className="flex items-center font-[500] border border-white3 dark:border-black2 rounded-[10px]  py-1.5 px-2 text-[15px]">
            v{iconVersion}
          </div>
        </div>
        <ul className="hidden lg:flex ml-[95px]">
          <li
            onClick={() => setShowLicense(true)}
            className="pr-10 text-[15px] leading-6 font-[500] cursor-pointer"
          >
            License
          </li>
          <li
            onClick={() => setShowChangeLog(true)}
            className="flex items-center pr-10 text-[15px] leading-6 font-[500] cursor-pointer"
          >
            <span>Patch Notes</span>
          </li>
          <li
            onClick={() => setShowRequestIcons(true)}
            className="text-[15px] leading-6 font-[500] cursor-pointer"
          >
            Request Icon
          </li>
        </ul>
        <div className="hidden lg:flex items-center">
          <a
            href="https://github.com/Mage-Icons/mage-icons"
            target="_blank"
            rel="noreferrer"
            className="flex items-center w-11 h-11 border border-white3 dark:border-black2 rounded-xl cursor-pointer mr-4"
          >
            <GithubIcon
              className={"w-6 h-6 mx-auto text-black2 dark:text-white"}
            />
          </a>
          <ElevatedButton
            textLabel="Download Icons"
            onClick={() => {
              window.open(downloadURL, "_blank");
            }}
            trailing={<DownloadIcon className="text-white ml-2 w-6 h-6" />}
            showShadow={true}
          />
          <span className="ml-3.5">
            <BrightnessSelector />
          </span>
        </div>
        {/* start mobile nav */}
        <span className="lg:hidden" onClick={handleNav}>
          <DashMenuIcon
            className={"dark:stroke-white stroke-black w-8 h-8 cursor-pointer"}
          />
        </span>
        <div
          className={`z-50 dark:bg-primaryBlack bg-white top-20 w-full h-full ease-in-out duration-500 lg:hidden ${
            !nav ? "left-[100%]" : "left-0"
          } ${!hidden ? "hidden" : "fixed"}`}
        >
          <ul className="text-[15px] leading-6 font-[500]">
            <li
              className="py-[15px] px-5 border-b border-white3 cursor-pointer"
              onClick={() => setShowLicense(true)}
            >
              License
            </li>
            <li
              className="flex py-[15px] px-5 border-b border-white3 cursor-pointer"
              onClick={() => setShowChangeLog(true)}
            >
              <span>Patch Notes</span>
            </li>
            <li
              onClick={() => setShowRequestIcons(true)}
              className="py-[15px] px-5 border-b border-white3 cursor-pointer"
            >
              Request Icon
            </li>
            <li
              onClick={() => setShowSupportUs(true)}
              className="flex py-[15px] px-5 border-b border-white3 cursor-pointer"
            >
              Support us
              <img
                src={SupportIcon}
                alt="Support us"
                className="mx-1 w-6 h-6"
              />
            </li>
            <li
              onClick={() =>
                window.open(
                  "https://github.com/Mage-Icons/mage-icons",
                  "_blank"
                )
              }
              className="py-[15px] px-5 border-b border-white3 cursor-pointer"
            >
              Github
            </li>
            <li
              onClick={() => {
                window.open(downloadURL, "_blank");
              }}
              className="py-[15px] px-5 cursor-pointer"
            >
              Download Icons
            </li>
            <li className="relative">
              <div className="fixed bottom-0 flex ml-3 bg-containerGray dark:bg-primaryBlack h-[52px] w-[96px] rounded-xl p-1.5 m-6">
                <div
                  onClick={() => {
                    setBrightness("light");
                  }}
                  className={`${
                    brightness === "light"
                      ? "bg-white dark:bg-containerGrayDark"
                      : ""
                  } flex justify-center cursor-pointer items-center h-10 w-10 rounded-xl text-base font-[450] leading-6`}
                >
                  <SunIcon />
                </div>
                <div
                  onClick={() => {
                    setBrightness("dark");
                  }}
                  className={`${
                    brightness === "dark"
                      ? "bg-white dark:bg-containerGrayDark"
                      : ""
                  } flex justify-center cursor-pointer items-center h-10 w-10 rounded-xl text-base font-[450] leading-6`}
                >
                  <MoonIcon />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
