import React from "react";

const ColorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1082_59)">
        <path
          d="M12 0L13.4063 2.98603L12 6.375C10.4532 6.375 9.04695 7.00781 8.02029 8.02027L4.34684 7.15931L3.54846 3.54844C5.71413 1.38281 8.70935 0 12 0Z"
          fill="#E64560"
        />
        <path
          d="M20.4516 3.54844L19.7446 7.06786L15.9797 8.02031C14.9531 7.00781 13.5468 6.375 12 6.375V0C15.2907 0 18.2859 1.38281 20.4516 3.54844Z"
          fill="#66B3FF"
        />
        <path
          d="M24 12L20.9467 13.4063L17.625 12C17.625 10.4531 16.9922 9.04684 15.9797 8.02028L20.4516 3.5484C22.6172 5.71403 24 8.70934 24 12Z"
          fill="#4D88FF"
        />
        <path
          d="M24 12C24 15.2906 22.6172 18.2859 20.4516 20.4516L16.5645 19.377L15.9797 15.9796C16.9922 14.9531 17.625 13.5468 17.625 12H24Z"
          fill="#7ACC52"
        />
        <path
          d="M20.4516 20.4515C18.2859 22.6172 15.2907 24 12 24L10.5938 20.7173L12 17.625C13.5468 17.625 14.9531 16.9922 15.9797 15.9796L20.4516 20.4515Z"
          fill="#ADE65C"
        />
        <path
          d="M12 17.625V24C8.70935 24 5.71413 22.6172 3.54846 20.4516L4.38035 16.8071L8.02034 15.9796C9.04695 16.9922 10.4532 17.625 12 17.625Z"
          fill="#FFEA80"
        />
        <path
          d="M8.02027 15.9796L3.54844 20.4516C1.38281 18.2859 0 15.2906 0 12L3.36159 10.5938L6.375 12C6.375 13.5468 7.00781 14.9531 8.02027 15.9796Z"
          fill="#FFCB66"
        />
        <path
          d="M8.02027 8.02029C7.00781 9.04685 6.375 10.4531 6.375 12H0C0 8.70935 1.38281 5.71404 3.54844 3.54846L8.02027 8.02029Z"
          fill="#FF6666"
        />
      </g>
      <defs>
        <clipPath id="clip0_1082_59">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ColorIcon;
