import React from "react";
import Modal from "./Modal";
import ElevatedButton from "./ElevatedButton";
import { StarIcon } from "mage-icons-react/bulk";
import MageIcon from "../icons/MageIcon";

const SupportUsPopup = ({ closeModal, modalOpen }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[579px] w-full p-5"
    >
      <h1 className="flex text-xl leading-8 font-[550] mb-3">
        <span className="mr-3">
          <MageIcon />
        </span>
        Support us
      </h1>
      <p className="text-base font-normal mb-5">
        Mage Icons is a high-quality product developed through significant
        dedication and effort from our team. This comprehensive collection of
        icons is available at no cost. We appreciate your support in using Mage
        Icons and are committed to continuing our work to provide high-quality
        icons. By choosing Mage Icons, you're supporting our mission and aiding
        our pursuit of excellence in icon design and development.
      </p>
      <div className="md:flex justify-between items-center">
        <ElevatedButton
          disabled={true}
          textLabel="Support Project"
          leading={<StarIcon className="text-white mr-1.5" />}
        />
        <div className="text-base font-normal mt-2.5 md:m-0 text-disabled">
          Support is disabled at the moment
        </div>
      </div>
    </Modal>
  );
};

export default SupportUsPopup;
