import React from "react";

const Tab = ({
  leading,
  label,
  trailing,
  onClick,
  selected,
  padding,
  margin,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected
          ? "bg-white dark:bg-containerGrayDark shadow-[0_4px_4px_4px_rgba(0,0,0,0.05)]"
          : ""
      } flex justify-center cursor-pointer items-center h-10 rounded-[10px] text-base font-[450] leading-6 ${padding} ${margin}`}
    >
      {leading && leading} {label && label}
      {trailing && <span className="hidden md:inline">&nbsp;{trailing}</span>}
    </div>
  );
};

export default Tab;
