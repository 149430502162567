import React from "react";
import Modal from "./Modal";

const PrivacyPopup = ({ closeModal, modalOpen }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[579px] w-full p-5"
    >
      <h1 className="text-xl leading-8 font-[550] mb-3">Privacy Policy</h1>
      <p className="text-base font-normal mb-3">We value your privacy</p>
      <p className="text-base font-normal mb-3">
        Mage Icons uses cookies to store information about a visitor's
        preferences and icon usage. This allows us to provide an enhanced and
        personalized user experience. All of this information is stored locally
        on the your device and not on our servers, ensuring we never gather your
        personal information.
      </p>
      <p className="text-base font-normal mb-3">
        To help us understand our users better and refine our products, we
        utilize Google Analytics for analyzing website traffic and user referral
        data. However, this tracking is performed directly by Google's servers
        and falls under Google's privacy policies. For further information,
        please{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
          className="underline text-base font-normal cursor-pointer text-accentBlue"
        >
          click here
        </a>
        .
      </p>
    </Modal>
  );
};

export default PrivacyPopup;
