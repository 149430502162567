import { useEffect, useState } from "react";

const useTheme = () => {
  const _key = 'brightness';

  const [brightness, setBrightness] = useState(window.localStorage.getItem(_key) ?? 'light');

  useEffect(() => {
    window.localStorage.setItem(_key, brightness);
  }, [brightness]);

  return [brightness, setBrightness];
}

export default useTheme;