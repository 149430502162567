import React from "react";
import Tab from "./Tab";

const TabBar = ({ tabs = [], onTabChange, activeTab }) => {
  return (
    <div className="flex bg-containerGray dark:bg-primaryBlack h-[52px] w-fit rounded-xl p-1.5 mb-4">
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            leading={tab.leading}
            trailing={tab.trailing}
            label={tab.label}
            selected={tab.value === activeTab}
            onClick={() => {
              if (onTabChange) {
                onTabChange(tab.value);
              }
            }}
            padding={tab.padding}
            margin={index !== tabs?.length - 1 ? "mr-1" : ""}
          />
        );
      })}
    </div>
  );
};

export default TabBar;
