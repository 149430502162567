import { useContext } from "react";
import { IconServiceContext } from "./IconServiceProvider";

const useIconService = () => {
  const {
    iconType,
    setIconType,
    iconSearch,
    setIconSearch,
    iconVersion,
    iconSize,
    setIconSize,
    subIconType,
    setSubIconType,
  } = useContext(IconServiceContext);

  return {
    iconType,
    setIconType,
    iconSearch,
    setIconSearch,
    iconVersion,
    iconSize,
    setIconSize,
    subIconType,
    setSubIconType,
  };
};

export default useIconService;
