import React, { useState } from "react";

export const PopupServiceContext = React.createContext();

export const PopupServiceProvider = ({ children }) => {
  const [showLicense, setShowLicense] = useState(false);
  const [showSupportUs, setShowSupportUs] = useState(false);
  const [showRequestIcons, setShowRequestIcons] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showChangeLog, setShowChangeLog] = useState(false);

  return (
    <PopupServiceContext.Provider
      value={{
        showLicense,
        setShowLicense,
        showSupportUs,
        setShowSupportUs,
        showRequestIcons,
        setShowRequestIcons,
        showPrivacy,
        setShowPrivacy,
        showAboutUs,
        setShowAboutUs,
        showChangeLog,
        setShowChangeLog,
      }}
    >
      {children}
    </PopupServiceContext.Provider>
  );
};
