import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/app.config";

const useFirebaseLogger = () => {
  const setFirebaseLog = (event, contentType, contentId) => {
    if (event && contentType && contentId) {
      logEvent(analytics, event, {
        content_type: contentType,
        content_id: contentId,
      });
    }
  };

  return [setFirebaseLog];
};

export default useFirebaseLogger;
