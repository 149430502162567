import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchInputBox from "./SearchInputBox";
import TabBar from "./TabBar";
import useIconService from "../hooks/iconService/useIconService";
import ColorIcon from "../assets/icons/ColorIcon";
import BWIcon from "../assets/icons/BWIcon";
// import { IconSizes } from "../hooks/iconService/IconServiceProvider";

const SearchToggle = () => {
  const {
    iconType,
    setIconType,
    iconSearch,
    setIconSearch,
    subIconType,
    setSubIconType,
    // iconSize,
    // setIconSize,
  } = useIconService();
  const [applyStickyBg, setApplyStickyBg] = useState(false);

  const stickyRef = useRef();

  const tabs = [
    {
      // leading: <HeartIcon className="mr-1 w-6 h-6" />,
      label: "Stroke",
      trailing: "Icons",
      value: "stroke",
      padding: "px-3.5",
    },
    {
      // leading: <StarIcon className="mr-1 w-6 h-6" />,
      label: "Fill",
      trailing: "Icons",
      value: "bulk",
      padding: "px-3.5",
    },
    {
      label: "Brand",
      trailing: "Icons",
      value: "social",
      padding: "px-3.5",
    },
  ];

  const colorTabs = [
    {
      leading: <BWIcon className="w-6 h-6" />,
      value: "bw",
      padding: "px-2",
    },
    {
      leading: <ColorIcon className="w-6 h-6" />,
      value: "color",
      padding: "px-2",
    },
  ];

  // const iconSizeTabs = [
  //   {
  //     leading: null,
  //     label: "24px",
  //     value: IconSizes["24px:"],
  //     padding: "px-2",
  //   },
  //   {
  //     leading: null,
  //     label: "20px",
  //     value: IconSizes["20px:"],
  //     padding: "px-2",
  //   },
  // ];

  const onIconThemeChange = (tab) => {
    setIconType(tab);
  };

  const onIconSearch = (e) => {
    setIconSearch(e.target.value);
  };

  const onClear = (e) => {
    setIconSearch("");
  };

  const stickyBgHandler = useCallback(() => {
    const elementOffsetTop = stickyRef.current.getBoundingClientRect();

    if (elementOffsetTop.y === 0) {
      setApplyStickyBg(true);
    } else {
      setApplyStickyBg(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", stickyBgHandler);

    return () => {
      window.removeEventListener("scroll", stickyBgHandler);
    };
  }, [stickyBgHandler]);

  return (
    <div
      ref={stickyRef}
      className={`sticky sticky:bg-black md:h-[84px] md:py-4 px-5 md:px-0 top-0 ${
        applyStickyBg
          ? "dark:bg-black3/30 backdrop-blur-lg opacity-[99] bg-white/30 border-b border-stickyBorderColor dark:border-stickyBorderColorDark"
          : ""
      }`}
      style={{ zIndex: 1 }}
    >
      <div className="max-w-[1120px] mx-auto">
        <div className="md:hidden w-full h-[152px] py-4">
          <div className="flex w-full justify-between h-[52px] mb-4">
            <TabBar
              tabs={tabs}
              activeTab={iconType}
              onTabChange={onIconThemeChange}
            />
            {iconType === "social" && (
              <div className="w-24">
                <TabBar
                  tabs={colorTabs}
                  activeTab={subIconType}
                  onTabChange={(tab) => {
                    setSubIconType(tab);
                  }}
                />
              </div>
            )}
          </div>
          <SearchInputBox
            className={"w-full h-[52px]"}
            onChange={onIconSearch}
            value={iconSearch}
            onClear={onClear}
            showTrailing={iconSearch?.length > 0}
          />
        </div>
        <div className="hidden md:flex justify-between w-full h-[52px]">
          <div className="w-[373px] mr-3.5">
            <SearchInputBox
              onChange={onIconSearch}
              value={iconSearch}
              onClear={onClear}
              showTrailing={iconSearch?.length > 0}
            />
          </div>
          <div className="flex">
            {iconType === "social" && (
              <div className="w-24 mr-3.5">
                <TabBar
                  tabs={colorTabs}
                  activeTab={subIconType}
                  onTabChange={(tab) => {
                    setSubIconType(tab);
                  }}
                />
              </div>
            )}
            <div>
              <TabBar
                tabs={tabs}
                activeTab={iconType}
                onTabChange={onIconThemeChange}
              />
            </div>
            {/* <div className="mr-3.5">
              <TabBar
                tabs={iconSizeTabs}
                activeTab={iconSize}
                onTabChange={setIconSize}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchToggle;
