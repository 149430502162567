import React from "react";

const TextField = ({ onChange, value, type, error }) => {
  return (
    <input
      type={type ?? "text"}
      className={`border ${
        error ? "border-error" : ""
      } focus:border-accentBlue focus:outline-none focus:ring-[1.5px] focus:ring-accentBlue dark:border-black2 dark:bg-primaryBlack p-3.5 rounded-[10px] w-full h-11`}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextField;
