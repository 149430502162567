import React, { useCallback, useEffect, useState } from "react";

const Modal = ({ closeModal, modalOpen, children, className }) => {
  const [modalState, setModalState] = useState(modalOpen);

  const keyDownHandler = useCallback(
    (e) => {
      if (e.code === "Escape" && closeModal && modalState) closeModal();
    },
    [modalState, closeModal]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [keyDownHandler]);

  useEffect(() => {
    setModalState(modalOpen);
  }, [modalOpen]);

  return (
    <div
      class={`${
        modalOpen ? "" : "hidden"
      } fixed z-50 bg-black bg-opacity-50 transition-opacity w-screen h-screen`}
    >
      <div
        className="flex justify-center items-center h-full w-full md-hidden"
        onClick={(e) => {
          if (closeModal) closeModal();
        }}
      >
        <div
          className={
            "bg-white dark:bg-primaryBlack rounded-2xl " + (className ?? "")
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
