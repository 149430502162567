import React, { useCallback, useContext } from "react";
import HomeContext from "../pages/HomeContext";
import SunIcon from "../icons/SunIcon";
import MoonIcon from "../icons/MoonIcon";

const BrightnessSelector = () => {
  const context = useContext(HomeContext);
  const { brightness, setBrightness } = context;

  const selected =
    "border dark:border-none shadow-[0_4px_4px_4px_rgba(0,0,0,0.05)] bg-white dark:bg-containerGrayDark";

  const toggleBrightness = useCallback(() => {
    if (brightness === "light") {
      setBrightness("dark");
    } else {
      setBrightness("light");
    }
  }, [brightness, setBrightness]);

  return (
    <div
      className="bg-containerGray dark:bg-black cursor-pointer flex justify-center items-center h-[44px] w-[80px] rounded-[90px]"
      onClick={toggleBrightness}
    >
      <div
        className={`flex items-center justify-center rounded-[47px] h-[36px] w-[36px] shadow-[0_4px_4px_4px_rgba(0,0,0,0.05)] ${
          brightness === "light" ? selected : ""
        }`}
        onClick={toggleBrightness}
      >
        <SunIcon />
      </div>
      <div
        className={`flex items-center justify-center rounded-[47px] h-[36px] w-[36px] ${
          brightness === "dark" ? selected : ""
        }`}
        onClick={toggleBrightness}
      >
        <MoonIcon />
      </div>
    </div>
  );
};

export default BrightnessSelector;
