import { useState } from "react";

const useCaptchaService = () => {
  const [token, setToken] = useState(null);

  const resetToken = () => {
    setToken(null);
  };

  return { token, setToken, resetToken };
};

export default useCaptchaService;
