import React, { useState } from "react";
import Modal from "./Modal";
import Accordion from "./Accordion";
import DarkModeIconList from "../changelogs/icons/Dark_Mode.png";
import LightModeIconList from "../changelogs/icons/Light_Mode.png";

const ChangeLogPopup = ({ closeModal, modalOpen }) => {
  const [openIndex, setOpenIndex] = useState(0);

  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[579px] w-full"
    >
      <div className="text-lg font-[600] pt-5 px-5 mb-4">Patch Notes</div>
      <Accordion
        label="Social Media + Massive Library Update"
        subLabel="0.7.0-beta"
        open={openIndex === 0}
        onClick={() => {
          setOpenIndex(0);
        }}
      >
        <p className="dark:text-[#E4E5E8] text-base font-normal mb-3 ml-12">
          <ul className="list-disc">
            <li>Added 50 Brand Icons</li>
            <li>Website updated with a fresh look and feel</li>
            <li>Increased the corner smoothing for squared icons</li>
            <li>More that 400+ Icons have been added to the overall mix.</li>
          </ul>
        </p>
      </Accordion>
      <Accordion
        label="Icon Tags and Quality Control"
        subLabel="0.0.6-beta"
        open={openIndex === 1}
        onClick={() => {
          setOpenIndex(1);
        }}
      >
        <p className="dark:text-[#E4E5E8] text-base font-normal mb-3 mx-12">
          <ul className="list-disc">
            <li>Mage Icons is now available on Figma community.</li>
            <li>Added support for React development environment.</li>
            <li>The Bulk Icons name has been changed to Fill Icons.</li>
            <li>
              Major update to the tags content. The tags for each icon has had
              major revamp to better reflect user data and better searchability
              for icons going further.
            </li>
            <li>
              Fixed bugs in some icons where artifacts were appearing due to
              overlapping of lines.
            </li>
            <li>Changes to the implementation of bulk icons for few icons</li>
          </ul>
          <ul className="list-disc">
            <li>Fixed bounding boxes of the icons to have more consistency.</li>
            <li>Improved the quality and consistency of stroke icons.</li>
            <li>
              Mage Icons now has added{" "}
              <span className="font-bold">50+ new icons.</span>
            </li>
          </ul>
        </p>
        <div className="mb-3">
          <img
            alt="Dark Mode Icons"
            className="dark:block hidden"
            src={DarkModeIconList}
          />
          <img
            alt="Light Mode Icons"
            className="dark:hidden"
            src={LightModeIconList}
          />
        </div>
      </Accordion>
      <Accordion
        label="Mage Icons Released"
        subLabel="0.0.5-beta"
        open={openIndex === 2}
        onClick={() => {
          setOpenIndex(2);
        }}
      >
        <p className="dark:text-[#E4E5E8] text-base font-normal mb-3 mx-12">
          <ul className="list-disc">
            <li>Mage Icons released for general use.</li>
          </ul>
        </p>
      </Accordion>
      <div className="pb-4"></div>
    </Modal>
  );
};

export default ChangeLogPopup;
