import React from "react";
import usePopupService from "../hooks/popupService/usePopupService";

const Footer = () => {
  const { setShowLicense, setShowPrivacy, setShowAboutUs, setShowSupportUs } =
    usePopupService();

  return (
    <div className="max-w-[1120px] mx-auto py-6 px-5 md:px-0">
      <div className="md:hidden flex flex-row flex-wrap justify-center text-base text-center items-center">
        <div
          onClick={() => {
            setShowLicense(true);
          }}
          className="basis-1/3 cursor-pointer"
        >
          License
        </div>
        <div
          onClick={() => {
            setShowPrivacy(true);
          }}
          className="basis-1/3 cursor-pointer"
        >
          Privacy
        </div>
        <div
          onClick={() => {
            setShowAboutUs(true);
          }}
          className="basis-1/3 cursor-pointer"
        >
          About us
        </div>
        <div className="my-4 md:my-0">Mage Icons ©️ 2023</div>
      </div>
      <div className="hidden md:flex justify-between text-base text-center items-center px-5 md:px-0">
        <div>Mage Icons ©️ 2023</div>
        <div className="flex justify-between">
          <div
            onClick={() => setShowSupportUs(true)}
            className="mr-10 cursor-pointer"
          >
            Support us
          </div>
          <div
            className="mr-10 cursor-pointer"
            onClick={() => {
              setShowLicense(true);
            }}
          >
            License
          </div>
          <div
            onClick={() => {
              setShowPrivacy(true);
            }}
            className="mr-10 cursor-pointer"
          >
            Privacy
          </div>
          <div
            onClick={() => {
              setShowAboutUs(true);
            }}
            className="cursor-pointer"
          >
            About us
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
