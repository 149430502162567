import React from "react";
import Modal from "./Modal";

const AboutUsPopup = ({ closeModal, modalOpen }) => {
  return (
    <Modal
      closeModal={closeModal}
      modalOpen={modalOpen}
      className="min-w-[350px] max-w-[350px] md:max-w-[507px] w-full p-5"
    >
      <h1 className="flex text-xl leading-8 font-[550] mb-3">About us</h1>
      <div className="flex w-full h-[74px] pt-[7px] items-center justify-start mb-4">
        <div className="min-h-[60px] min-w-[60px] h-[60px] w-[60px] bg-gray3 rounded-[14px]">
          <img
            src="nischal.jpg"
            className="min-h-[60px] min-w-[60px] h-[60px] w-[60px] bg-gray3 rounded-[14px]"
            alt="Nischal Kharel"
          />
        </div>
        <div className="ml-4">
          <h1 className="text-lg font-[550]">Nischal Kharel</h1>
          <div className="text-sm font-[400]">
            Product Designer @walcybank. nis.kharel@gmail.com
          </div>
        </div>
      </div>
      <div className="flex w-full h-[74px] pt-[7px] items-center justify-start">
        <div className="min-h-[60px] min-w-[60px] h-[60px] w-[60px] bg-gray3 rounded-[14px]">
          <img
            src="https://avatars.githubusercontent.com/u/9513691?v=4"
            className="min-h-[60px] min-w-[60px] h-[60px] w-[60px] bg-gray3 rounded-[14px]"
            alt="Bug The Debugger"
          />
        </div>
        <div className="ml-4">
          <h1 className="text-lg font-[550]">Prayush Bijukchhe</h1>
          <div className="text-sm font-[400]">
            Software Engineering Manager @portpro. bugthedebugger@gmail.com
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AboutUsPopup;
