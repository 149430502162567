import React from "react";
import { FigmaIcon } from "mage-icons-react/social-color";
import { mageIconList } from "mage-icons-react";

const Hero = () => {
  return (
    <div className="max-w-[1120px] mt-[128px] mx-auto flex px-5 md:px-0">
      <div className="md:basis-3/4 w-full z-10">
        <div className="flex justify-start items-center mb-[18px]">
          <div className="flex items-center font-[500] border border-white3 dark:border-black2 rounded-[49px] py-1.5 px-3 text-sm">
            {mageIconList.bulk.length +
              mageIconList.stroke.length +
              mageIconList.socialBW.length +
              mageIconList.socialColor.length}{" "}
            Icons
          </div>
          <div className="ml-6 font-[500] text-sm">Apache 2.0 License</div>
        </div>
        <div className="text-[54px] font-[700] line-[48.41px]">
          <span>Open Source</span>
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#56CCF2] to-[#31A5EA]">
            {" "}
            Icon System
          </span>
        </div>
        <div className="flex justify-center mt-10 text-lg font-[450] leading-[28.8px] lg:leading-8 w-full md:w-[634px]">
          Welcome to Mage Icons. Consistent and elegant icons for your next
          design project. Mage icons is a curated library for user interface
          design for web and mobile applications.
        </div>
        <div className="hidden md:flex flex-wrap justify-left mt-10 text-lg lg:text-xl leading-[28.8px] lg:leading-8 font-normal">
          <a
            href="https://www.figma.com/community/file/1269612629751747014/Mage-Icons-Version-0.0.6"
            target="_blank"
            rel="noreferrer"
            className="flex w-full md:w-max justify-center items-center h-[44px] font-medium bg-stickyBorderColorDark text-[16px] p-3.5 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer md:mr-3.5"
          >
            <FigmaIcon className="mr-2" />
            <span className="text-white">Figma Community</span>
          </a>
          <a
            href="https://www.npmjs.com/package/mage-icons-react"
            target="_blank"
            rel="noreferrer"
            className="flex w-full md:w-max justify-center items-center h-[44px] font-medium bg-buttonGray dark:bg-primaryBlack text-[16px] p-3.5 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer mt-3 lg:mt-0 md:mt-0 md:mr-3.5"
          >
            React JS
          </a>
          <button
            rel="noreferrer"
            className="flex w-full md:w-max justify-center items-center h-[44px] font-medium bg-buttonGray dark:bg-primaryBlack text-[16px] pt-3.5 pb-3.5 pl-3.5 pr-2 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer mt-3 lg:mt-0 md:mt-0"
          >
            <span>Web Fonts</span>
            <span className="rounded-md flex text-white bg-success p-2 text-sm h-[26px] items-center justify-center ml-2">
              Coming Soon
            </span>
          </button>
        </div>

        {/* mobile buttons */}
        <div className="flex md:hidden mt-8 mb-3.5">
          <a
            href="https://www.figma.com/community/file/1269612629751747014/Mage-Icons-Version-0.0.6"
            target="_blank"
            rel="noreferrer"
            className="flex w-full justify-center items-center h-[44px] font-medium bg-stickyBorderColorDark text-[16px] p-3.5 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer mr-3.5"
          >
            <FigmaIcon className="mr-2" />
            <span className="text-white">Figma Community</span>
          </a>
          <a
            href="https://www.npmjs.com/package/mage-icons-react"
            target="_blank"
            rel="noreferrer"
            className="flex w-[150px] justify-center items-center h-[44px] font-medium bg-buttonGray dark:bg-primaryBlack text-[16px] p-3.5 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer"
          >
            React JS
          </a>
        </div>
        <button
          rel="noreferrer"
          className="flex md:hidden w-full justify-center items-center h-[44px] font-medium bg-buttonGray dark:bg-primaryBlack text-[16px] pt-3.5 pb-3.5 pl-3.5 pr-2 border border-white3 dark:border-black2 rounded-[10px] cursor-pointer"
        >
          <span>Web Fonts</span>
          <span className="rounded-md flex text-white bg-success p-2 text-sm h-[26px] items-center justify-center ml-2">
            Coming Soon
          </span>
        </button>
      </div>
      <div className="md:basis-1/4 hidden md:block lg:block"></div>
    </div>
  );
};

export default Hero;
