import React from "react";
import { MultiplyIcon, SearchIcon } from "mage-icons-react/stroke";

const SearchInputBox = ({
  className,
  onChange,
  onClear,
  value,
  showTrailing,
}) => {
  return (
    <div className={`flex items-center ${className ?? ""}`}>
      <div className="relative w-full h-full z-10">
        <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
          <SearchIcon className="text-gray1" />
        </div>
        <input
          type="text"
          className="w-full h-full pl-12 border focus:border-accentBlue focus:outline-none focus:ring-[1.5px] focus:ring-accentBlue dark:border-black2 rounded-xl p-3.5 bg-gray2 dark:bg-primaryBlack"
          placeholder="Search Icons"
          onChange={onChange}
          value={value ?? ""}
        />
        {showTrailing && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={onClear}
          >
            <MultiplyIcon className="text-gray1" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchInputBox;
