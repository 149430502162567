import React, { useMemo } from "react";
import IconCard from "./IconCard";
import * as MageIconsStroke from "mage-icons-react/stroke";
import * as MageIconsBulk from "mage-icons-react/bulk";
import * as MageIconsSocialBW from "mage-icons-react/social-bw";
import * as MageIconsSocialColor from "mage-icons-react/social-color";
import { mageIconList } from "mage-icons-react";
import useIconService from "../hooks/iconService/useIconService";
import useFirebaseLogger from "../hooks/firebaseLogger/useFirebaseLogger";
import { exactMatch } from "../utils/search.utils";
import { IconSizes } from "../hooks/iconService/IconServiceProvider";

const IconGrid = () => {
  const { iconType, iconSearch, iconSize, subIconType } = useIconService();
  const [setFirebaseLog] = useFirebaseLogger();

  const searchIcons = (query, iconName, iconTags) => {
    if (iconName?.toLowerCase()?.match(query?.toLowerCase())) {
      return true;
    }

    for (const iconTag of iconTags ?? []) {
      if (iconTag?.toLowerCase()?.match(query?.toLowerCase())) {
        return true;
      }
    }

    return false;
  };

  const strokeIconFilter = useMemo(() => {
    const priorityList = [];
    const iconList = [];

    if (!iconSearch || iconSearch?.length === 0) return null;

    for (const icon of mageIconList.stroke ?? []) {
      if (exactMatch(iconSearch, icon.fileName)) {
        priorityList.push(icon);
        continue;
      }

      if (searchIcons(iconSearch, icon.fileName, icon.tags)) {
        iconList.push(icon);
      }
    }

    return [...priorityList, ...iconList].filter(Boolean);
  }, [iconSearch]);

  const bulkIconFilter = useMemo(() => {
    const priorityList = [];
    const iconList = [];

    if (!iconSearch || iconSearch?.length === 0) return null;

    for (const icon of mageIconList.bulk ?? []) {
      if (exactMatch(iconSearch, icon.fileName)) {
        priorityList.push(icon);
        continue;
      }

      if (searchIcons(iconSearch, icon.fileName, icon.tags)) {
        iconList.push(icon);
      }
    }

    return [...priorityList, ...iconList].filter(Boolean);
  }, [iconSearch]);

  const socialBWFilter = useMemo(() => {
    const priorityList = [];
    const iconList = [];

    if (!iconSearch || iconSearch?.length === 0) return null;

    for (const icon of mageIconList.socialBW ?? []) {
      if (exactMatch(iconSearch, icon.fileName)) {
        priorityList.push(icon);
        continue;
      }

      if (searchIcons(iconSearch, icon.fileName, icon.tags)) {
        iconList.push(icon);
      }
    }

    return [...priorityList, ...iconList].filter(Boolean);
  }, [iconSearch]);

  const socialColorFilter = useMemo(() => {
    const priorityList = [];
    const iconList = [];

    if (!iconSearch || iconSearch?.length === 0) return null;

    for (const icon of mageIconList.socialColor ?? []) {
      if (exactMatch(iconSearch, icon.fileName)) {
        priorityList.push(icon);
        continue;
      }

      if (searchIcons(iconSearch, icon.fileName, icon.tags)) {
        iconList.push(icon);
      }
    }

    return [...priorityList, ...iconList].filter(Boolean);
  }, [iconSearch]);

  const copyToClipboard = (data) => {
    if (iconSize === IconSizes["20px:"]) {
      let dataClone = data;
      dataClone = dataClone.replace(`width="24"`, `width="20"`);
      dataClone = dataClone.replace(
        `height="24"`,
        `height="20" preserveAspectRatio="xMidYMid meet" `
      );
      dataClone = dataClone.replace(`stroke-width="1.5"`, `stroke-width="1.8"`);
      navigator.clipboard.writeText(dataClone);
    } else {
      navigator.clipboard.writeText(data);
    }
  };

  return (
    <div className="max-w-[1120px] mx-auto mt-2.5 md:mt-8 px-5 md:px-0">
      <div className="grid sm:grid-cols-5 lg:grid-cols-12 md:grid-cols-6 grid-cols-4 gap-3.5 justify-between w-full">
        {iconType === "stroke" &&
          (strokeIconFilter ?? mageIconList.stroke).map((e, index) => {
            const Icon = MageIconsStroke[e.reactIconName];

            return (
              <IconCard
                key={index}
                Icon={<Icon className={"w-7 h-7"} />}
                iconName={e.fileName}
                onClick={() => {
                  copyToClipboard(e.svg);
                  setFirebaseLog("copy-svg", "icon-stroke", e.fileName);
                }}
              />
            );
          })}
        {iconType === "bulk" &&
          (bulkIconFilter ?? mageIconList.bulk).map((e, index) => {
            const Icon = MageIconsBulk[e.reactIconName];

            return (
              <IconCard
                key={index}
                Icon={
                  <Icon
                    className={"w-7 h-7 text-darkIconColor dark:text-white"}
                  />
                }
                iconName={e.fileName}
                onClick={() => {
                  copyToClipboard(e.svg);
                  setFirebaseLog("copy-svg", "icon-bulk", e.fileName);
                }}
              />
            );
          })}
        {iconType === "social" &&
          subIconType === "bw" &&
          (socialBWFilter ?? mageIconList.socialBW).map((e, index) => {
            const Icon = MageIconsSocialBW[e.reactIconName];

            return (
              <IconCard
                key={index}
                Icon={
                  <Icon
                    className={"w-7 h-7 text-darkIconColor dark:text-white"}
                  />
                }
                iconName={e.fileName}
                onClick={() => {
                  copyToClipboard(e.svg);
                  setFirebaseLog("copy-svg", "icon-bulk", e.fileName);
                }}
              />
            );
          })}
        {iconType === "social" &&
          subIconType === "color" &&
          (socialColorFilter ?? mageIconList.socialColor).map((e, index) => {
            const Icon = MageIconsSocialColor[e.reactIconName];

            return (
              <IconCard
                key={index}
                Icon={<Icon className={"w-7 h-7"} />}
                iconName={e.fileName}
                onClick={() => {
                  copyToClipboard(e.svg);
                  setFirebaseLog("copy-svg", "icon-bulk", e.fileName);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default IconGrid;
