import axios from "axios";

const { useEffect, useState } = require("react");

const useDownloadService = () => {
  const latestReleaseURL =
    "https://api.github.com/repos/Mage-Icons/mage-icons/releases/latest";

  const [downloadURL, setDownloadURL] = useState(null);

  const fetchLatestURL = () => {
    axios.get(latestReleaseURL).then((response) => {
      for (const asset of response?.data?.assets ?? []) {
        if (asset.name === "mage-icons-svg.zip") {
          setDownloadURL(asset.browser_download_url);
          break;
        }
      }
    });
  };

  useEffect(() => {
    fetchLatestURL();
  }, []);

  return { downloadURL };
};

export default useDownloadService;
