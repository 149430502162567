import React from "react";

const ElevatedButton = ({
  onClick,
  textLabel,
  trailing,
  leading,
  showShadow = false,
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center min-w-[190px] w-full md:w-min h-11 ${
        disabled
          ? "bg-disabled"
          : "bg-gradient-to-b from-[#1484EF] to-[#0072DE]"
      }  rounded-xl py-2.5 px-3.5 ${
        showShadow ? "shadow-[0_11px_23px_rgba(0,114,222,0.42)]" : ""
      }`}
      disabled={disabled}
    >
      {leading && leading}
      <div className="text-white font-medium text-[17px] leading-6">
        {textLabel ?? "Placeholder"}
      </div>
      {trailing && trailing}
    </button>
  );
};

export default ElevatedButton;
