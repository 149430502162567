import React from "react";

const MoonIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8443 14.4441C19.3973 15.7422 18.6274 16.9053 17.6071 17.8239C16.5868 18.7425 15.3497 19.3865 14.0119 19.6953C12.6742 20.0041 11.2799 19.9676 9.96018 19.5892C8.64045 19.2107 7.43868 18.5028 6.46788 17.532C5.49709 16.5612 4.78918 15.3595 4.41074 14.0397C4.0323 12.72 3.99577 11.3257 4.30458 9.98799C4.6134 8.65026 5.2574 7.41306 6.17603 6.39276C7.09465 5.37246 8.25771 4.6026 9.5558 4.15557C9.71328 4.09587 9.88436 4.08159 10.0496 4.11435C10.2148 4.14711 10.3674 4.2256 10.4902 4.34088C10.613 4.45616 10.7009 4.60359 10.744 4.7664C10.7871 4.92921 10.7836 5.10084 10.7339 5.26177C10.3028 6.54497 10.2776 7.9299 10.662 9.22789C10.9411 10.2059 11.465 11.0966 12.1841 11.8158C12.9033 12.5349 13.794 13.0588 14.772 13.3379C16.07 13.7223 17.4549 13.6971 18.7381 13.266C18.8991 13.2163 19.0707 13.2128 19.2335 13.2559C19.3963 13.299 19.5438 13.3869 19.659 13.5097C19.7743 13.6325 19.8528 13.7852 19.8856 13.9504C19.9183 14.1156 19.904 14.2866 19.8443 14.4441Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoonIcon;
