import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6666 8.60001V11.4C10.6666 13.7333 9.73325 14.6667 7.39992 14.6667H4.59992C2.26659 14.6667 1.33325 13.7333 1.33325 11.4V8.60001C1.33325 6.26668 2.26659 5.33334 4.59992 5.33334H7.39992C9.73325 5.33334 10.6666 6.26668 10.6666 8.60001Z"
        fill="white"
      />
      <path
        d="M11.4 1.33334H8.59996C6.54463 1.33334 5.57996 2.06268 5.37996 3.82601C5.33729 4.19468 5.64329 4.50001 6.01463 4.50001H7.39996C10.2 4.50001 11.5 5.80001 11.5 8.60001V9.98534C11.5 10.3567 11.8046 10.662 12.1733 10.62C13.938 10.42 14.6666 9.45534 14.6666 7.40001V4.60001C14.6666 2.26668 13.7333 1.33334 11.4 1.33334Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyIcon;
