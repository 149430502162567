import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import useTheme from "../hooks/theme/useTheme";
import HomeContext from "./HomeContext";
import IconGrid from "../components/IconGrid";
import Footer from "../components/Footer";
import { IconServiceProvider } from "../hooks/iconService/IconServiceProvider";
import { PopupServiceProvider } from "../hooks/popupService/popupServiceProvider";
import Popups from "../components/Popups";
import SearchToggle from "../components/SearchToggle";
import Loading from "./Loading";

const Home = () => {
  const [brightness, setBrightness] = useTheme();
  const [onContentLoaded, setOnContentLoaded] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (!fadeIn && onContentLoaded) {
      setFadeIn(true);
    }
  }, [fadeIn, onContentLoaded]);

  return (
    <HomeContext.Provider value={{ brightness, setBrightness }}>
      <IconServiceProvider>
        <PopupServiceProvider>
          <div className={brightness}>
            <Loading
              onLoadingComplete={() => {
                setOnContentLoaded(true);
              }}
            />
            <div className="font-inter bg-primaryBackground dark:bg-black dark:text-white text-primaryBlack scroll-smooth">
              <Popups />
            </div>
            {onContentLoaded && (
              <div
                className={`font-inter min-h-screen bg-primaryBackground dark:bg-black dark:text-white text-primaryBlack scroll-smooth transition-opacity transition-transform ease-out duration-700 ${
                  fadeIn
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-14"
                }`}
              >
                <div className="relative bg-gradient-to-t from-primaryBackground to-white dark:bg-none md:pb-[106px] pb-[56px]">
                  <div className="absolute top-56 items-center justify-center h-20 w-full mx-auto pt-3 px-5 dark:hidden hidden md:hidden lg:flex z-0 select-none">
                    <div className="flex items-center justify-end w-full max-w-[1800px]">
                      <img
                        alt="."
                        src="halfcircle.png"
                        className="gradient-img"
                      />
                    </div>
                  </div>
                  <Header />
                  <Hero />
                </div>
                <SearchToggle />
                <IconGrid />
                <Footer />
              </div>
            )}
          </div>
        </PopupServiceProvider>
      </IconServiceProvider>
    </HomeContext.Provider>
  );
};

export default Home;
